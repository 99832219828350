import React from "react";
import MinimalPortfolio from "./components/MinimalPortfolio";

export default function App() {
  return (
    <div className={`app light`}>
      {/* <Portfolio />
      <PrintInfo /> */}
      <MinimalPortfolio />
    </div>
  );
}
